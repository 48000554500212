import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  cta: ButtonProps
  title?: string
}

export const SimpleCTA = memo(function SimpleCTA({ cta, title }: Props) {
  if (!cta) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <CTA variant="outline" {...cta} />
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 0 1.875rem 9rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 0 2rem 5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 1.75rem;
`
