import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  description?: string
  images: ImageProps[]
  title?: string
}

export const Stripe = memo(function Stripe({
  cta,
  description,
  images,
  title,
}: Props) {
  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [opacities, setOpacities] = useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: images.length,
    loop: true,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      images.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Carousel>
        <Spinner variant="simple" />
        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              style={
                index !== 0
                  ? {
                      opacity: opacities[index],
                    }
                  : { opacity: 1 }
              }
            >
              <Image
                {...item}
                media="(min-width: 2800px)"
                src={loaded[index] ? item.src : undefined}
                srcSet={loaded[index] ? item.srcSet : undefined}
              />
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <Arrows className="zz-arrows" row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="invert"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="invert"
            />
          </Arrows>
        ) : null}
      </Carousel>

      <Wrapper className="zz-wrapper">
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <CTA variant="simple" {...cta} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 2.6875rem;
  &:first-of-type {
    margin-top: 0;
  }
  &:nth-of-type(even) {
    .zz-wrapper {
      margin-right: 3.75rem;
      margin-left: auto;
    }
    .zz-arrows {
      right: auto;
      left: 7.778vw;
    }
  }
`

const Carousel = styled.div`
  height: 37.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  position: relative;

  @media (max-width: 1023px) {
    height: 33.5vh;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 4.125rem;
  right: 7.778vw;
  z-index: 2;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 60.556vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-left: 3.75rem;
  padding: 4rem 4.25rem 2.875rem 3.75rem;
  position: relative;
  z-index: 3;
  transform: translateY(-5.4375rem);
  &:before {
    content: '';
    width: calc(100% - 3.75rem);
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: -1.25rem;
    right: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
    padding: 3.5rem 2rem;
    transform: none;
    &:before {
      width: calc(100% - 2rem);
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  letter-spacing: 0.075rem;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
