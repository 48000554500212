import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Props as StripeProps, Stripe } from './Stripe'

export interface Props {
  stripes: StripeProps[]
}

export const VisualZigZag = memo(function VisualZigZag({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      {stripes.map((item, index) => (
        <Stripe key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 9rem;

  @media (max-width: 1023px) {
    padding-bottom: 5rem;
  }
`
